<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new cashbox</div>
      <div v-else class="p-dialog-title">Cashbox editing</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="cashbox_name" :class="{'p-invalid' : submitted && !cashboxData.name}" v-model="cashboxData.name" autocomplete="off"/>
          <label for="cashbox_name">Name<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !cashboxData.name">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="cashbox_description" v-model="cashboxData.description" autocomplete="off"/>
          <label for="cashbox_description">Description</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <MultiSelect @change="changeSelectedEmployees"
                       @show="toogleDropdownOverlayVisibility(true)"
                       @hide="toogleDropdownOverlayVisibility(false)"
                       id="employees"
                       v-model="selectedEmployees"
                       :options="employeesData"
                       optionLabel="full_name"
                       :filter="true"
                       rows="4"
                       display="chip"/>
          <label for="employees">{{ $t('Employees') }}</label>
        </div>
      </div>
    </div>


    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";

export default {
  mixins: [ httpMixins, overlayVisibilityMixins ],
  emits: ['close', 'update-items'],
  name: 'CashboxModal',
  props: {
    item: Object,
    visible: Boolean,
    employees: Array,
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      employeesData: [],
      cashboxData: {},
      submitted: false,
      selectedEmployees: null,
      updateEmployees: false
    }
  },
  watch: {
    item(value) {
      this.cashboxData = { ...value }
      if (value.users?.length) {
        const sortedUsers = value.users.sort((a, b) => a.id - b.id)
        this.selectedEmployees = sortedUsers.map(user => {
          const masterInMastersDataArray = this.employeesData.find(m => m.id === user.user?.id)
          if (!masterInMastersDataArray) {
            this.employeesData.push({id: user.user.id, full_name: (user.user.first_name || '') + ' ' + (user.user.last_name || '')})
          }
          return {id: user.user.id, full_name: (user.user.first_name || '') + ' ' + (user.user.last_name || '')}
        })
      }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        this.selectedEmployees = null
      }
    },
    employees(value) {
      this.employeesData = [...value]
    },
  },
  methods: {
    changeSelectedEmployees() {
      this.updateEmployees = true
    },
    async saveItem() {
      this.submitted = true
      if (!this.cashboxData.name) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const cashbox = {
        id: this.cashboxData.id ?? null,
        name: this.cashboxData.name ? this.cashboxData.name : null,
        description: this.cashboxData.description ? this.cashboxData.description : null,
        branch_id: this.$store.state.branchData?.id ?? null
      }

      if (this.updateEmployees) {
        cashbox.employees = this.selectedEmployees.length ? this.selectedEmployees.map(employee => employee.id) : null
      } else {
        cashbox.employees = null
      }


      if (this.cashboxData.id) {
        if (this.updateEmployees && !this.selectedEmployees.length) {
          cashbox.removeAllEmployees = true
        }

        try {
          const { status, data } = await httpClient.post(`cashbox/update`, cashbox)
          if (status === 200 && data?.success) {
            this.$emit('update-items', this.cashboxData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
            this.close()
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`cashbox/create`, cashbox)
          if (status === 201 && data?.success) {
            this.cashboxData.id = data.id
            cashbox.id = data.id
            this.$emit('update-items', this.cashboxData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>